<template>
  <div class="form-container">
    <div class="mb-4">
      <h1 class="mb-3">Forgot your password?</h1>
      <p>
        Forgot password? Input your email address and a reset link will be sent to you.
      </p>
    </div>
    <div class="form-group">
      <label for="">Email Address</label>
      <input
        v-model="email"
        type="email"
        class="form-control"
        placeholder="e.g someone@example.com"
      />
    </div>
    <b-alert
      :show="requestAgain"
      dismissible
      variant="warning"
      @dismissed="requestAgain = 0"
      @dismiss-count-down="countDownChanged"
    >
      You'll be able to request again after {{ requestAgain }} seconds...
    </b-alert>
    <b-alert
      v-for="(err, i) in errors"
      :key="i"
      v-model="err.visible"
      class="mt-4"
      variant="danger"
      dismissible
    >
      {{ err.message }}
    </b-alert>
    <div class="form-group m-mt-40">
      <b-button
        block
        variant="tertiary"
        :class="classObject"
        :disabled="btnDisabled"
        @click="requestPasswordReset()"
      >
        {{ processing ? "" : "Send Reset Link" }} <RingLoader v-if="processing" />
      </b-button>
    </div>
    <div class="row-space justify-content-end">
      <router-link to="/login">Log in to your dashboard</router-link>
    </div>
  </div>
</template>
<script>
import setErrorMixin from "@/mixins/setErrorMixin";
import RingLoader from "@/components/loader/RingLoader";

export default {
  title: "Menaget - Talent Dashboard Forgot Password?",
  components: { RingLoader },
  mixins: [setErrorMixin],
  data() {
    return {
      email: "",
      errors: [],
      requestAgain: 0,
    };
  },
  computed: {
    btnDisabled() {
      return this.email === "";
    },
  },
  methods: {
    countDownChanged(requestAgain) {
      this.requestAgain = requestAgain;
    },
    requestPasswordReset() {
      this.processing = true;
      this.$store
        .dispatch("auth/requestPasswordReset", this.email)
        .then(() => {
          this.email = "";
        })
        .catch((err) => {
          let data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
          if (data?.status === 429) {
            this.requestAgain = data?.data.request_again;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
